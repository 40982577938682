import React from "react";
import WaitlistEmail from "./WaitlistEmail";
import {
  AcademicCapIcon,
  BanknotesIcon,
  CheckBadgeIcon,
  ClockIcon,
  ReceiptRefundIcon,
  UsersIcon,
  HomeModernIcon,
} from "@heroicons/react/24/outline";
import logo from "../assets/Logo Text.png"; // Ensure the path is correct

// Import headshots from assets folder
import terrence from "../assets/terrence.jpg";
import ibrahim from "../assets/ibrahim.png";
import heaven from "../assets/heaven.jpg";
import taki from "../assets/taki.png";
import peter from "../assets/peter.png";

const team = [
  {
    name: "Terrence Marshall, CPA",
    role: "Team Leader, Realtor",
    imageUrl: terrence,
  },
  {
    name: "Ibrahim Abowath",
    role: "Team Leader, Realtor",
    imageUrl: ibrahim,
  },
  {
    name: "Heaven Kaymen Joseph",
    role: "Partner, Realtor",
    imageUrl: heaven,
  },
  {
    name: "Taki Wong",
    role: "Chief Product Officer",
    imageUrl: taki,
  },
  {
    name: "Peter Zhang",
    role: "Chief Technology Officer",
    imageUrl: peter,
  },
];

const awards = [
  {
    title: "Ranked #1 Group in Keller Williams Legacies",
    icon: CheckBadgeIcon,
    iconForeground: "text-indigo-700",
    iconBackground: "bg-indigo-50",
  },
  {
    title: "Ranked #6 Group in Canada, Q1 2024",
    icon: CheckBadgeIcon,
    iconForeground: "text-indigo-700",
    iconBackground: "bg-indigo-50",
  },
  {
    title: "$175M sales volume",
    icon: BanknotesIcon,
    iconForeground: "text-indigo-700",
    iconBackground: "bg-indigo-50",
  },
  {
    title: "200+ properties sold",
    icon: HomeModernIcon,
    iconForeground: "text-indigo-700",
    iconBackground: "bg-indigo-50",
  },
  {
    title: "10+ years of experience",
    icon: ClockIcon,
    iconForeground: "text-indigo-700",
    iconBackground: "bg-indigo-50",
  },
  {
    title: "150+ happy clients",
    icon: UsersIcon,
    iconForeground: "text-indigo-700",
    iconBackground: "bg-indigo-50",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const AboutUs = () => {
  return (
    <section id="mission" className="bg-white py-24 sm:py-18 text-center">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mt-12">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Our Team
          </h2>
          <ul
            role="list"
            className="mx-auto mt-10 grid max-w-none grid-cols-1 gap-x-8 gap-y-16 text-center sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
            {team.map((person) => (
              <li key={person.name}>
                <img
                  className="mx-auto h-22 w-24 rounded-full object-cover"
                  src={person.imageUrl}
                  alt={person.name}
                />
                <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-gray-900">
                  {person.name}
                </h3>
                <p className="text-sm leading-6 text-gray-600">{person.role}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className="mx-auto max-w-2xl mt-20">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Our Mission
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Our mission is to make the homebuying experience efficient and
            stress-free for everyone - from first time homebuyers to repeat
            investors - by using cutting edge AI technology to find the dream
            property.
          </p>
        </div>

        <div className="mx-auto max-w-2xl">
          <div>
            <img
              src={logo}
              alt="Synergy Real Estate Logo"
              className="mx-auto h-48 w-auto"
            />
          </div>
          <p className="text-lg pb-10 leading-8 text-gray-600">
            Some of our awards in 2024 so far…
          </p>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            {awards.map((award, awardIdx) => (
              <div
                key={award.title}
                className={classNames(
                  award.iconBackground,
                  "flex items-center space-x-4 rounded-lg p-4"
                )}>
                <div
                  className={classNames(
                    award.iconBackground,
                    award.iconForeground,
                    "flex-shrink-0 h-12 w-12 rounded-lg inline-flex items-center justify-center"
                  )}>
                  <award.icon className="h-6 w-6" aria-hidden="true" />
                </div>
                <div className="text-left">
                  <h4 className="text-lg font-medium text-gray-900">
                    {award.title}
                  </h4>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
