import React from "react";
import { ChevronDoubleDownIcon } from "@heroicons/react/24/solid";

const Problem = () => {
  return (
    <section id="problem-solution" className="bg-gray-100">
      <div className="mx-auto max-w-8xl ">
        <div className="py-16 px-6 text-center bg-indigo-100">
          <h2 className="text-2xl font-semibold text-gray-900">
            Today's home buying experience is time consuming and confusing.
          </h2>
          <p className="mt-4 text-lg text-gray-700">
            Synergy AI makes this process efficient by using AI to recommend you
            the perfect property and realtor to help you secure the best deal.
            Say goodbye to endless searching and uncertainty.
          </p>
        </div>

        <div className="flex py-4">
          <ChevronDoubleDownIcon
            className="mx-auto h-8 w-8 text-indigo-600"
            aria-hidden="true"
          />{" "}
          <ChevronDoubleDownIcon
            className="mx-auto  h-8 w-8 text-indigo-600"
            aria-hidden="true"
          />
        </div>

        <div className="px-6 py-16 text-center bg-indigo-600">
          <h2 className="text-2xl font-semibold text-white">
            Synergy AI is the solution
          </h2>
          <p className="mt-4 text-lg text-white">
            Synergy AI combines personalized property recommendations,
            intelligent buyer and realtor matches, and advanced communication
            tools in a single platform, revolutionizing the real estate
            experience.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Problem;
