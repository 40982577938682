import React, { useState, useEffect } from "react";
import { EnvelopeIcon } from "@heroicons/react/20/solid";
import { db, collection, addDoc } from "../firebase/firebase"; // Adjust the import path as needed

const WaitlistEmail = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (submitted) {
      const timer = setTimeout(() => {
        setSubmitted(false);
      }, 3000);
      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }
  }, [submitted]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValidEmail(email)) {
      try {
        await addDoc(collection(db, "emails"), { email });
        console.log("Email submitted:", email);
        setSubmitted(true);
        setEmail("");
        setIsValid(true); // Reset the validity state on successful submission
        setError(null); // Clear any existing error
      } catch (error) {
        console.error(error);
        setError("Failed to submit email. Please try again later.");
      }
    } else {
      setIsValid(false);
      setError("Please enter a valid email address.");
    }
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  return (
    <div className="text-center">
      <form
        onSubmit={handleSubmit}
        className="mt-10 flex flex-col items-center gap-x-6">
        <div className="relative mt-2 rounded-md shadow-sm">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <EnvelopeIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            type="email"
            name="email"
            id="email"
            className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="you@example.com"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setIsValid(true); // Reset validity when the user starts typing
              setSubmitted(false); // Reset the submission state when the user starts typing
              setError(null); // Reset error state
            }}
            required
          />
        </div>
        {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
        <button
          type="submit"
          className="mt-4 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          disabled={!email}>
          Join the waitlist
        </button>
      </form>
      {submitted && (
        <p className="mt-4 text-sm font-semibold text-green-600">
          Thank you! Your email has been submitted.
        </p>
      )}
    </div>
  );
};

export default WaitlistEmail;
