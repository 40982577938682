import React from "react";
import {
  ChartBarIcon,
  SparklesIcon,
  LinkIcon,
  ChatBubbleLeftRightIcon,
} from "@heroicons/react/24/outline";
import "./Feature.css";
import details from "../assets/details.png";

const Feature = () => {
  const primaryFeatures = [
    {
      name: "Personalized Property Recommendations",
      description: (
        <>
          <p>
            Our AI-driven system learns from your preferences and behavior to
            provide property suggestions that match your exact criteria, saving
            you time and effort in your home search.
          </p>
        </>
      ),
      href: "#",
      icon: SparklesIcon,
    },
    {
      name: "Quality Realtor Connections",
      description: (
        <>
          <p>
            Connect with award-winning, highly rated realtors who are matched to
            your specific needs and preferences, ensuring a trustworthy and
            personalized homebuying experience.
          </p>
        </>
      ),
      href: "#",
      icon: LinkIcon,
    },
    {
      name: "Improved Communications",
      description: (
        <>
          <p>
            Enjoy seamless communication with your realtor through integrated
            tools that allow for easy messaging, document sharing, and
            scheduling, ensuring you stay informed and on track.
          </p>
        </>
      ),
      href: "#",
      icon: ChatBubbleLeftRightIcon,
    },
    {
      name: "Real-Time Market Data",
      description: (
        <>
          <p>
            Stay ahead with up-to-date market insights and neighborhood
            information, helping you make informed decisions about where and
            when to buy.
          </p>
        </>
      ),
      href: "#",
      icon: ChartBarIcon,
    },
  ];

  return (
    <section id="homebuyer">
      <div className="mx-auto mt-20 max-w-7xl px-6 lg:px-8">
        <div className="mx-auto mt-20 max-w-2xl lg:text-center">
          {/* <h2 className="text-base font-semibold leading-7 text-indigo-600">
            Homebuyer
          </h2> */}
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Find your dream home and realtor
          </p>
        </div>

        <div className="mx-auto mt-12 max-w-2xl sm:mt-12 lg:mt-16 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
            {primaryFeatures.map((feature) => (
              <div key={feature.name} className="feature-tile">
                <dt className="text-base font-semibold pb-2 leading-7 text-gray-900 flex items-center">
                  {feature.icon && (
                    <div className="feature-icon">
                      <feature.icon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                  <span className="ml-2">{feature.name}</span>
                </dt>
                <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <div className="flex-auto">{feature.description}</div>
                </dd>
              </div>
            ))}
          </dl>
        </div>

        <div className="flex justify-center mt-16 sm:mt-24">
          <div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
            <img
              src={details}
              alt="Property Details Page"
              className="w-full h-auto rounded-md shadow-2xl"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature;
