import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Problem from "./components/Problem";
import Feature from "./components/Feature";
import AboutUs from "./components/AboutUs";
import CTA from "./components/CTA";
import Footer from "./components/Footer";

function App() {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch("/api")
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <div className="bg-white">
      <Header />
      <main className="isolate">
        <Hero />
        <Problem />
        <Feature />
        <AboutUs />
        <CTA />
      </main>
      <Footer />
    </div>
  );
}

export default App;
