// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB6t5ik5gMZjrODYrcET8SS9b7i3uQaMBM",
  authDomain: "propulsionai-dcbb5.firebaseapp.com",
  projectId: "propulsionai-dcbb5",
  storageBucket: "propulsionai-dcbb5.appspot.com",
  messagingSenderId: "101220330609",
  appId: "1:101220330609:web:90db4a0017d6def1303fd0",
  measurementId: "G-1LC3BZ5181"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db, collection, addDoc };
