import React from "react";
import { SocialIcon } from "react-social-icons";

const navigation = [
  {
    name: "Instagram",
    href: "https://www.instagram.com/synergyhomeai?igsh=bDI2MTU3b2FlMTR6",
  },
  {
    name: "TikTok",
    href: "https://www.tiktok.com/@synergyhomeai?_t=8nHrCDNbCi6&_r=1",
  },
  {
    name: "Discord",
    href: "https://discord.gg/Xw9qQ874H2",
  },
];

export default function Footer() {
  return (
    <footer className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-12 pb-30 flex flex-col items-center lg:px-8">
        <div className="flex justify-center space-x-6">
          {navigation.map((item) => (
            <SocialIcon
              key={item.name}
              url={item.href}
              className="text-gray-400 hover:text-gray-500"
              target="_blank"
              rel="noopener noreferrer"
              style={{ height: 35, width: 35 }}
            />
          ))}
        </div>
        <div className="mt-8 text-center">
          <p className="text-xs leading-5 text-gray-500">
            Synergy AI is built by Synergy Real Estate, a Keller Williams
            Legacies brokerage.
          </p>
          <p className="mt-4 text-xs leading-5 text-gray-500">
            &copy; 2024 Synergy AI Inc. All rights reserved. Powered by Keller
            Williams Legacy Synergy Real Estate.
          </p>
        </div>
      </div>
    </footer>
  );
}
